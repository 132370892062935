import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { BsArrowRightShort } from "react-icons/bs";
import THEME from "../shared/theme";
import PageLink from "./PageLink";

// creating constants for all of the possible button prop values that come from wordpress
// buttonLinkTypes
// const PAGE = "page";
const EMAIL = "email";
const SOLID = "solid";
const WHITE = "white";
const PURPLE = "purple";
const GOLD = "gold";
const PINK = "pink";
const NAVY = "navy";
const TEAL = "teal";
const DARK_PURPLE = "darkPurple";
const DARK_RED = "darkRed";

const CTASolid = styled.button`
  /* width: 169px;
  height: 54px; */
  justify-self: center;
  border-radius: 35px;
  font-size: 1.8rem;
  font-family: ${props => props.theme.fonts.condensedMedium};
  letter-spacing: 2.4px;
  text-align: left;
  /* line-height: 25px; */
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  a {
    display: block;
    padding: 16px 25px;
    color: ${props => props.theme.colors.offWhite} !important;
    text-decoration: none !important;
  }
  &:hover a {
    text-decoration: underline !important;
  }
`;
const CTAWhite = styled.button`
  /* width: 208px;
  height: 81px; */
  justify-self: center;
  border-radius: none;
  font-size: 1.8rem;
  letter-spacing: 2.7px;
  text-align: left;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.condensedMedium};
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  a {
    display: block;
    // padding: 27px 32px;
    padding: 16px 25px;
  }
  &:hover {
    text-decoration: underline;
    background-color: ${props => props.theme.colors.white};
  }

  svg {
    margin-left: 10px;
    display: inline-block;
    height: 30px;
    width: 30px;
  }
`;
const renderButtonChildren = (type, link, linkType, email, text) => (
  <>
    {linkType === EMAIL ? (
      <a href={`mailto:${email}`}>{text}</a>
    ) : link?.url?.startsWith("/") ? (
      <PageLink to={link?.url}>{link?.title}</PageLink>
    ) : (
      <a target={link.target} href={link.url}>
        {link?.title}
      </a>
    )}
    {type === WHITE && <BsArrowRightShort />}
  </>
);
const renderButton = (type, color, link, linkType, email, text, props) => {
  const { colors } = THEME;
  switch (type) {
    case SOLID:
      switch (color) {
        case PURPLE:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.lighterPurple};
                border-color: ${colors.lightestPurple};
                &:hover {
                  background-color: ${colors.lightestPurple};
                }
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTASolid>
          );
        case GOLD:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.darkGold};
                border-color: ${colors.lightGold};
                &:hover {
                  background-color: ${colors.lightGold};
                }
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTASolid>
          );
        case PINK:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.mediumPink};
                border-color: ${colors.lightPink};
                &:hover {
                  background-color: ${colors.lightPink};
                }
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTASolid>
          );
        case NAVY:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.darkestPurple};
                border-color: ${colors.darkerPurple};
                &:hover {
                  background-color: ${colors.darkerPurple};
                }
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTASolid>
          );
        case TEAL:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.mediumTeal};
                border-color: ${colors.lightTeal};
                &:hover {
                  background-color: ${colors.lightTeal};
                }
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTASolid>
          );
        case DARK_RED:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.darkRed};
                border-color: ${colors.lightRed};
                &:hover {
                  background-color: ${colors.lightRed};
                }
              `}
            >
              {renderButtonChildren(type, link, linkType, email, text, props)}
            </CTASolid>
          );
        default:
          return (
            <CTASolid
              css={css`
                background-color: ${colors.lighterPurple};
                border-color: ${colors.lightestPurple};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTASolid>
          );
      }
    case WHITE:
      switch (color) {
        case GOLD:
          return (
            <CTAWhite
              css={css`
                background-color: ${colors.offWhite};
                border-color: ${colors.lightPeach};
                color: ${colors.darkGold};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTAWhite>
          );
        case PURPLE:
          return (
            <CTAWhite
              css={css`
                background-color: ${colors.offWhite};
                border-color: ${colors.lightPeach};
                color: ${colors.lighterPurple};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTAWhite>
          );
        case PINK:
          return (
            <CTAWhite
              css={css`
                background-color: ${colors.offWhite};
                border-color: ${colors.lightPeach};
                color: ${colors.mediumPink};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTAWhite>
          );
        case DARK_PURPLE:
          return (
            <CTAWhite
              css={css`
                background-color: ${colors.offWhite};
                border-color: ${colors.lightPeach};
                color: ${colors.darkerPurple};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTAWhite>
          );
        case NAVY:
          return (
            <CTAWhite
              css={css`
                background-color: ${colors.offWhite};
                border-color: ${colors.lightPeach};
                color: ${colors.darkestPurple};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTAWhite>
          );
        default:
          return (
            <CTAWhite
              css={css`
                background-color: ${colors.offWhite};
                border-color: ${colors.lightPeach};
                color: ${colors.darkGold};
              `}
              {...props}
            >
              {renderButtonChildren(type, link, linkType, email, text)}
            </CTAWhite>
          );
      }
    default:
      return (
        <CTASolid
          css={css`
            background-color: ${colors.darkGold};
            border-color: ${colors.lightGold};
          `}
          {...props}
        >
          {renderButtonChildren(type, link, linkType, email, text)}
        </CTASolid>
      );
  }
};

const CtaButton = ({ linkType, email, text, type, color, link, ...props }) => {
  return link
    ? renderButton(type, color, link, linkType, email, text, props)
    : null;
};

CtaButton.propTypes = {
  linkType: PropTypes.oneOf(["page", "email"]),
  email: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(["solid", "white"]).isRequired, // usually xxxxx.buttonType
  color: PropTypes.oneOf([
    PURPLE,
    GOLD,
    PINK,
    NAVY,
    TEAL,
    DARK_PURPLE,
    DARK_RED,
  ]).isRequired, // usually xxxxx.buttonColor
  link: PropTypes.shape({
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired, // usually xxxxx.buttonLink
};
export default CtaButton;
